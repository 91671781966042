.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #eef0f4;

  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
}

.img-pdf{
  width: 100% !important;
  img{
    width: 50%;
  }
}
