.banner-sostenibilidad {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 450px;
}
.div-video {
  padding: 70px 0px;
  background: #084f9a;
  .title-video {
    color: #fff;
    text-align: center;
    padding: 50px 0px;
  }
  .file-video {
    width: 60%;
    margin: 0 auto;
    height: 500px;
    border-radius: 20px;

    -webkit-box-shadow: 10px 10px 20px 0px #000000;
    -moz-box-shadow: 10px 10px 20px 0px #000000;
    box-shadow: 10px 10px 20px 0px #000000;
  }
  @media (max-width: 1020px) {
    .file-video {
      width: 90%;
    }
  }
}
.div-desc-list {
  background-image: url(../../assets/background-home-clients.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain !important;
  .div-description-text {
    padding: 100px 50px;
    text-align: center;
    p {
      font-size: 18px;
    }
  }
  .div-list {
    max-width: 80%;
    margin: 0 auto;
    padding-bottom: 50px;

    .item-list {
      display: flex;
      width: 100%;

      .div-video-main{
        width: 100%;
      }
      .div-video-alone{
        flex: 0 0 50%;
        margin: 0 auto;
      }

      div {
        flex: 1;
        margin: 20px 0px;
      }

      img,
      .react-player {
        height: auto;
      }

      img{
        width: 350px;
      }
      
    }
  }
  @media (max-width: 1020px) {
    .div-list {
      max-width: 90%;
    }
  }
  @media (max-width: 768px) {
    .item-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      text-align: center;
    
      div {
        flex: 0 0 100%; 
        margin: 20px 0;
        height: 350px;
      }

      .div-video-alone{
        flex: 0 0 100%;
        margin: 20px 0 !important;
      }

      img,
      .react-player {
        max-width: 100%;
        height: 350px;
      }

      img{
        width: 90% !important;
        margin: 0 auto;
      }
    }
    
  }
}

.div-pdflist{
  padding: 50px 0px;
  background: #eef0f4;
  .div-search{
    width: 40%;
    margin: 0 auto;
    text-align: center;

    input{
      padding: 10px 20px;
    }
  }
}
.div-contact-form{
  background-image: url(../../assets/background-home-clients.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover !important;
  padding: 100px 50px;
  text-align: center;

  .cta-contact-form{
    margin-bottom: 30px;
  }
}

/* Skeleton styles */
.skeleton-container {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  padding: 50px 100px;
}

.skeleton-item {
  width: 300px;
  height: 290px;
  background-color: #e0e0e0; 
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.skeleton-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  animation: skeleton-loading 1.5s infinite;
}

@keyframes skeleton-loading {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
